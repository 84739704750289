import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import axios from '@axios'
import appCategoryModel from './appCategoryModel'

const {
  fetchList,
  fetchAll,
  resolveRoute,
} = useModelStoreModule(appCategoryModel)

const fetchOne = (ctx, id) => new Promise((resolve, reject) => {
  axios
    .get(resolveRoute('show', id))
    .then(response => {
      if (response && (response.status === 200)) {
        return resolve(response.data)
      }
      return reject(new Error('Operation Failed!'))
    })
    .catch(error => reject(error))
})

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList,
    fetchAll,
    fetchOne,
  },
}
