<template>
  <div>
    <b-row>
      <b-col>
        <b-alert
          variant="info"
          icon="AlertTriangleIcon"
          show
          dismissible
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="Edit3Icon"
            />
            {{ $t('modules.apps._messages.suggest_marketplace_app') }} <a
              :href="`https://docs.google.com/forms/d/e/1FAIpQLSeUe-sLsxpjXVhz3S9ovByOF47kZuRTz909KZEvnAFCYvusHg/viewform?usp=pp_url&entry.349755060=${shop().url}`"
              target="_blank"
            >{{ $t('modules.apps.actions.suggest') }}</a>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert
          variant="info"
          icon="AlertTriangleIcon"
          show
          dismissible
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="CodeIcon"
            />
            {{ $t('modules.apps._messages.join_developers_message') }} <a
              href="https://forms.gle/vnYxcwnwpgY47nLeA"
              target="_blank"
            >{{ $t('modules.apps.actions.join_now') }}</a>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BAlert,
} from 'bootstrap-vue'
import { shop } from '@core/utils/utils'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
  },
  methods: { shop },
}
</script>
